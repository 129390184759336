<template>
  <div>
    
    <el-dialog v-model="show_dialog" width="1400px" append-to-body :show-close="false">
      <template #title>
        <div class="dialog_box">
          <div class="con_title"><span></span> 产品账页</div>
          <div class="new_search_box">
            <div style="margin-top:0px">
                <span class="mr_20">
                  <el-button type="primary" icon="el-icon-upload2"  :loading="is_export" @click="try_export">导出</el-button>
                </span>
                <span class="mr_20">
                  产品：
                  <el-select  v-model="queryParams.product_id" placeholder="请输入产品编号/产品名称/助记码" clearable remote :remote-method="remoteMethod"
                      style="width:240px" filterable>
                      <el-option :label="item.product_name" v-for="(item,index) in prolist"
                          :key="index" :value="item.id"></el-option>
                  </el-select>
                </span>
                <span class="mr_20">
                  时间：
                  <el-date-picker
                    v-model="queryParams.time"
                    type="daterange"  value-format="YYYY-MM-DD" 
                    placeholder="请选择时间"
                    range-separator="至" style="width: 300px;"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </span>
              <el-button type="primary" @click="handleQuery()">搜索</el-button>
              <el-button @click="resetQuery()">重置</el-button>
              
            </div>
          </div>
        </div>
      </template>
      <div>
        <el-table :data="list" v-loading="loading" style="width: 100%">
            <el-table-column prop="receipt_date" label="日期" align="center" />
            <el-table-column prop="receipt_no" label="单据编号" align="center" />
            <el-table-column prop="item_name" label="摘要"  align="center"/>
            <el-table-column label="对方单位" align="center">
                <el-table-column prop="merchant_no" label="编号" align="center" />
                <el-table-column prop="merchant_name" label="名称" align="center" />
            </el-table-column>
            <el-table-column label="入库" align="center">
                <el-table-column prop="purchase_num" label="数量" align="center" />
                <el-table-column prop="purchase_price" label="单价" align="center" />
                <el-table-column prop="purchase_sum_price" label="金额" align="center" />
            </el-table-column>
            <el-table-column label="出库" align="center">
                <el-table-column prop="sale_num" label="数量" align="center" />
                <el-table-column prop="sale_price" label="金额"  align="center"/>
            </el-table-column>
            <el-table-column label="结存" align="center">
                <el-table-column prop="balance_num" label="数量"  align="center"/>
                <el-table-column prop="balance_price" label="金额" align="center" />
            </el-table-column>
            <el-table-column prop="batch_number" label="批号"  align="center"/>
            <el-table-column prop="sale_sum_amount" label="销售额" align="center" />
            <el-table-column prop="gross_profit_price" label="毛利" align="center" />
        </el-table>
        
        <pagination
          v-show="total>0"
          :total="total"
          v-model:page="queryParams.page"
          v-model:limit="queryParams.size"
          @pagination="getList"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="show_dialog = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'import',
  props: {
    
  },
  data() {
    return {
        show_dialog:false,
        // 列表模板
        loading: false,
        // 查询参数
        queryParams: {
            page: 1,
            size: 10,
            product_id: '',
            time:''
        },
        total:0,
        list:[],
        is_export:false,
        prolist:[]
    }
  },
  methods: {
    init(id){
        this.queryParams.product_id=id;
        this.show_dialog=true;
        this.getList();
        this.remoteMethod()
    },
    remoteMethod(keyword){
        this.$httpGet("/backend/product.Product/index", {size:10,product_no:keyword}).then((res) => {
            if (res.status == 200) {
                this.prolist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    resetQuery(){
        this.queryParams= {
            page: 1,
            size: 10,
            product_id: '',
            time:''
        }
        this.getList()
    },
    /** 搜索按钮操作 */
    handleQuery() {
        this.queryParams.page = 1;
        this.getList();
    },
    try_export(){
        if(this.is_export){return false;}
        this.is_export = true;
        let _data={...this.queryParams};
        _data.startTime=_data.time?_data.time[0]:'';
        _data.endTime=_data.time?_data.time[1]:'';
        _data.type=1
        this.$httpGet("/backend/ProductStockRecord/export", _data).then((res) => {
            if (res.status == 200) {
                  var a = document.createElement('a');
                  let name=res.data.file.split('/')
                  var fileName = name[name.length-1];
                  a.download = fileName;
                  a.href = this.$http+'/'+res.data.file;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
            } else {
                this.$message.error(res.message);
            }
            this.is_export = false;
        }).catch((err) => {
            console.log(err);
            this.is_export = false;
        });
    },
    getList(){
        this.loading = true;
        let _data={...this.queryParams};
        _data.startTime=_data.time?_data.time[0]:'';
        _data.endTime=_data.time?_data.time[1]:'';
        _data.type=1
        this.$httpGet("/backend/ProductStockRecord/index", _data).then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.list = res.data.data;
                this.total = res.data.total;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
